import React, { useState, useEffect, useCallback } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { useNavigate } from "react-router-dom";
import "react-accessible-accordion/dist/fancy-example.css";
import {
  formatDateDash,
  getFirstNumberFromString,
  handleCatch,
  isDispensingMoreThan3DaysBetween2Dates,
  isDispensingMoreThan7DaysBetween2Dates,
  isObject,
} from "../../../shared/helpers";
import moment from "moment";

import StyledPrescriptionAuthorisationForm from "./StyledPAF";

// import { ReactComponent as DownloadBlue } from "../../../assets/icons/downloadBlue.svg";
import { ReactComponent as Valid } from "../../../assets/icons/valid.svg";
import { ReactComponent as Error } from "../../../assets/icons/error-small.svg";
import { ReactComponent as ShortGreenArrow } from "../../../assets/icons/shortGreenArrow.svg";
import { ReactComponent as RevertApprover } from "../../../assets/icons/revertApprover.svg";
import { ReactComponent as ValidWhite } from "../../../assets/icons/valid-white.svg";
import { ReactComponent as Rejected } from "../../../assets/icons/rejected.svg";
import CloseIcon from "../../../assets/icons/close.svg";

import { App } from "../../../routes/Constants";
import QuitModal from "../../common/Modal/QuitModal";
import InfoPAF from "../../common/InfoPAF/InfoPAF";
import ViewPAF from "../../common/ViewPAF/ViewPAF";
import ConfirmationModal from "./confirmationModal";
import APIVariables from "../../../services/api-variables";
import { typeOfPAF } from "../../../constants/typeOfPAF";
import { Virtuoso } from "react-virtuoso";
import ChangeValuesModal from "./ChangeValuesModal";
import NonCompliantPAF from "./NonCompliantPAF";
import Loader from "../../common/Loader/Loader";
import { errorMessages } from "../../../constants/errorMessages.constants";
import ContinueNonCompliantPAF from "./ContinueNonCompliantPAF";
import NominateDispenser from "./NominateDispenser";
import FinishModal from "./FinishModal";
import MahSelection from "./MahSelection";
import useRoles from "../../../hooks/useRoles";
import BrochureWithText from "../Brochure/BrochureWithText";
import Axios from "../../../services/Axios";
import OverdueWarningModal from "./OverdueWarningModal";

const PrescriptionAuthorisationForm = () => {
  const patientID = window.location.pathname.split("/")[2];
  const pafID = window.location.pathname.split("/")[3];

  const {
    isPrescriber,
    isConnectedNurse,
    isHomecare,
    isPharmacy,
    isOutpatient,
    storageData,
  } = useRoles();
  const [userInfo, setUserInfo] = useState([]);
  const [items, setItems] = useState([]);
  const [unchagedItems, setUnchagedItems] = useState([]);
  const [errorMessage, setErrorMessage] = useState(""); //errors from backend
  const navigate = useNavigate();
  const [quitModalIsOpen, setIsOpenQuit] = useState(false);
  const [statePAFModal, setStatePAFModal] = useState(false);
  const [message, setMessage] = useState({
    state: 1,
    type: "",
    title: "",
    description: "",
    buttonText: "",
  });
  const [isFormApproved, setIsFormApproved] = useState(null);
  const [form, setForm] = useState({
    reason_rejection: "",
    reason_rejection_input: "",
  });

  const [revert, setRevert] = useState({
    reason_revert: "",
    reason_revert_input: "",
  });
  const [detailsPAF, setDetailsPAF] = useState([]);
  const [aditionalOptions, setAditionalOptions] = useState({
    capsuleStrength: false,
    capsulePerCycle: false,
    numberOfCycles: false,
    numberOfWeeks: false,
  });

  const [aditionalOptionsState, setAditionalOptionsState] = useState({
    showErrors: false,
    validation: {
      capsuleStrength: {
        isValid: !!detailsPAF[0]?.strengths?.map(
          (strength) => !!strength?.strength_value
        ),
      },
      capsulePerCycle: {
        isValid: !!detailsPAF[0]?.strengths?.map(
          (strength) => !!strength?.capsules
        ),
      },
      numberOfCycles: {
        isValid:
          detailsPAF[0]?.n_cycles === null
            ? !!detailsPAF[0]?.new_capsule_cycles?.id
              ? !!detailsPAF[0]?.new_capsule_cycles?.id
              : !!detailsPAF[0]?.n_weeks_other
            : !!detailsPAF[0]?.n_cycles,
      },

      numberOfWeeks: {
        isValid:
          detailsPAF[0]?.schedule_display_type === "weeks_per_cycle"
            ? Boolean(detailsPAF[0]?.weeks_per_cycle)
              ? true
              : Boolean(detailsPAF[0]?.new_weeks_per_cycle?.id)
              ? true
              : Boolean(detailsPAF[0]?.new_weeks_per_cycle_other)
              ? true
              : false
            : true,
      },
    },
    areErrors: true,
  });

  const isValidStrengths =
    detailsPAF[0]?.strengths?.length > 0
      ? detailsPAF[0]?.strengths?.every((strength) => {
          if (
            strength.strength_id === "Incomplete" &&
            Boolean(strength?.new_strength_id)
          ) {
            return true;
          } else if (Boolean(strength.strength_id)) {
            return true;
          } else {
            return false;
          }
        })
      : false;

  const isValidCapsules =
    detailsPAF[0]?.strengths?.length > 0
      ? detailsPAF[0]?.strengths?.every((strength) => {
          if (
            strength.capsules === "Incomplete" &&
            Boolean(strength?.new_capsules)
          ) {
            return true;
          } else if (
            Boolean(strength.capsules) &&
            strength.capsules !== "Incomplete"
          ) {
            return true;
          } else {
            return false;
          }
        })
      : false;

  const isValidCycles = Boolean(detailsPAF[0]?.n_cycles)
    ? true
    : Boolean(detailsPAF[0]?.new_capsule_cycles?.id)
    ? true
    : Boolean(detailsPAF[0]?.n_weeks_other)
    ? true
    : false;

  const isValidWeeksPerCycle = Boolean(detailsPAF[0]?.weeks_per_cycle)
    ? true
    : Boolean(detailsPAF[0]?.new_weeks_per_cycle?.id)
    ? true
    : Boolean(detailsPAF[0]?.new_weeks_per_cycle_other)
    ? true
    : false;

  useEffect(() => {
    setAditionalOptionsState((prevState) => ({
      ...prevState,
      validation: {
        capsuleStrength: {
          isValid: isValidStrengths,
        },
        capsulePerCycle: {
          isValid: isValidCapsules,
        },
        numberOfCycles: {
          isValid: isValidCycles,
        },
        numberWeeksPerCycle: {
          isValid:
            detailsPAF[0]?.schedule_display_type === "weeks_per_cycle"
              ? isValidWeeksPerCycle
              : true,
        },
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailsPAF]);

  const [activatedItem, setActivateTab] = useState([]);
  const [numberOfCylesValues, setNumberOfCylesValue] = useState([]);
  const [showNominateDispenser, setShowNominateDispenser] = useState(false);
  const [showMahSelection, setShowMahSelection] = useState(false);
  const [compliantPaf, setCompliantPAF] = useState(false);
  const [isApproveAndDispense, setIsApproveAndDispense] = useState(false);
  const [finishModalOpen, setFinishModalOpen] = useState(false);
  const [finishModalContent, setFinishModalContent] = useState({
    title: "",
    content: "",
    onFinish: () => true,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isOtherNumberOfCycledValid, setIsOtherNumberOfCycledValid] =
    useState(false);
  const lastFormID = items.length && items[0].id;

  const [changeValuesModalIsOpen, setIChangeValuesModal] = useState({
    isOpen: false,
    title: "",
    description: "",
    typeOfError: "",
  });
  const [confirmWasChangedPregancyTest, setConfirmWasChangedPregancyTest] =
    useState(null);
  const [
    confirmWasPregancyTestIsNotValid,
    setConfirmWasPregancyTestIsNotValid,
  ] = useState(null);
  const [confirmWasApproved, setConfirmWasApproved] = useState(null);
  const [confirmWasDispensed7DaysAfter, setConfirmWasDispensed7DaysAfter] =
    useState(null);
  const [confirmWasChangedNumberOfCycles, setConfirmWasChangedNumberOfCyles] =
    useState(null);
  const [confirmWasChangedWeeksPerCycle, setConfirmWasChangedWeeksPerCycle] =
    useState(null);
  const [confirmChangesCapsStrength, setConfirmChangesCapsStrength] =
    useState(null);
  const [confirmChangesCapsPerCycle, setConfirmChangesCapsPerCycle] =
    useState(null);
  const [modalNonCompliantPAF, setModalNonCompliantPAF] = useState({
    isOpen: false,
    description: [],
    screen: 1,
  });

  const [isOperationInProgress, setIsOperationInProgress] = useState(false);

  // TODO: Extract each state for modal into a object
  const [
    confirmPregnancyTestDateIncompleteModal,
    setConfirmPregnancyTestDateIncompleteModal,
  ] = useState(null);
  const [
    confirmNumberOfCylesIsNotValidWarningModal,
    setConfirmNumberOfCylesIsNotValidWarningModal,
  ] = useState(null);
  const [
    confirmNumberOfWeeksIsNotValidWarningModal,
    setConfirmNumberOfWeeksIsNotValidWarningModal,
  ] = useState(null);
  const [
    confirmNumberOfWeeksIsNotValidAgainWarningModal,
    setConfirmNumberOfWeeksIsNotValidAgainWarningModal,
  ] = useState(null);
  const [
    confirmCapsulesPerWeeksIsNotValidWarningModal,
    setConfirmCapsulesPerWeeksIsNotValidWarningModal,
  ] = useState(null);
  const [
    confirmCheckboxIsNotValidWarningModal,
    setConfirmCheckboxIsNotValidWarningModal,
  ] = useState(null);
  const [
    confirmIndicationIncompleteModal,
    setConfirmIndicationIncompleteModal,
  ] = useState(null);

  const [modalNonCompliantPAFWarning, setModalNonCompliantPAFWarning] =
    useState({
      svg: null,
      isOpen: false,
      title: "",
      description: [],
    });
  const [validPassword, setValidPassword] = useState("");
  const [errorMessageFromServer, setErrorMessageFromServer] = useState([]);
  const isLastFormApprovedAvailableForRenew =
    items &&
    items.length &&
    (items[0].status_name === "Approved" ||
      items[0].status_name === "Dispensed" ||
      items[0].status_name === "Rejected");
  const [errors, setErrors] = useState({});
  const [dispensingForm, setDispensingForm] = useState({
    type: null,
    location: null,
    confirmation_risk: false,
  });

  const [hcp, setHcp] = useState();
  const getHCP = async (productid) => {
    try {
      const config = {
        headers: {
          Authorization: storageData?.token,
        },
      };

      const data = await Axios.get(
        APIVariables.HCP_BROCHURE_INFO + productid,
        config
      );
      setHcp(data.data);
    } catch (error) {
      handleCatch(error);
    }
  };

  const setOpenedItem = (data) => {
    const detailsPAFIndex = detailsPAF?.findIndex(
      (element) => element.id === data.id
    );

    if (detailsPAFIndex === -1) {
      setDetailsPAF([...detailsPAF, data]);
    } else {
      detailsPAF[detailsPAFIndex] = data;
      setDetailsPAF([...detailsPAF]);
    }
  };

  const getOpenedItem = useCallback(() => {
    const detailsPAFIndex = detailsPAF?.findIndex(
      (element) => element.id === activatedItem
    );

    if (detailsPAFIndex === -1) {
      return {};
    }

    return detailsPAF[detailsPAFIndex];
  }, [activatedItem, detailsPAF]);

  const [continuDispenseOverduePAF, setContinuDispenseOverduePAF] = useState({
    isConfirmedMainModal: false,
    isMainModalDisplayed: false,
    isValidNonComplaintHomecareAndOutPatient: false,
  });

  const [capsule_changes_tracker, setCapsule_changes_tracker] = useState({});

  useEffect(() => {
    getNumberOfCycles();
    // added window.location.pathname fixed a bug when finding a patient from top menu is not chanching the UI
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  useEffect(() => {
    getPAF();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientID]);

  // useEffect(() => {
  //   setIsFormApproved(null);
  // }, [items]);

  useEffect(() => {
    validateAditionalOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aditionalOptions]);

  const updateNotifications = () => {
    document.dispatchEvent(new CustomEvent("fetchNotifications"));
  };

  const [childObject, setChildObject] = useState(null);
  const updateChildObject = (newValue) => {
    setChildObject([newValue]);
  };
  useEffect(() => {
    if (
      detailsPAF?.length > 0 &&
      childObject?.length > 0 &&
      childObject.some((item) => Object.keys(item)?.length > 0) &&
      JSON.stringify(detailsPAF) !== JSON.stringify(childObject)
    ) {
      setDetailsPAF([...childObject]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childObject]);

  const validateAditionalOptions = () => {
    const validation = {
      capsuleStrength: {
        isValid: isValidStrengths,
      },
      capsulePerCycle: {
        isValid: isValidCapsules,
      },
      numberOfCycles: {
        isValid: isValidCycles,
      },
      numberWeeksPerCycle: {
        isValid:
          detailsPAF[0]?.schedule_display_type === "weeks_per_cycle"
            ? isValidWeeksPerCycle
            : true,
      },
    };

    let areErrors = Object.values(validation).some((field) => {
      return !field.isValid;
    });
    setAditionalOptionsState((prevState) => ({
      ...prevState,
      validation: validation,
      areErrors: areErrors,
    }));
  };

  function typeOfForm() {
    const patientType =
      userInfo.patient_type || getOpenedItem()?.patient?.patient_type;

    if (patientType === "Male") {
      return typeOfPAF.MALE;
    } else if (patientType === "Woman of Non-Childbearing Potential") {
      return typeOfPAF.WOMANOFNCP;
    } else {
      return typeOfPAF.WOMANOFCPRENEW2;
    }
  }

  const handleAditionalOptionsChange = (e, type = "input", name) => {
    if (type === "select") {
      setAditionalOptions({ ...aditionalOptions, [name]: e });
    } else {
      const {
        target: { name, value },
      } = e;

      setAditionalOptions({ ...aditionalOptions, [name]: value });
    }
  };

  const closeFinishModal = () => {
    setFinishModalOpen(false);
    setIsOperationInProgress(false);
  };

  const validateOutpatientDispense = (data) => {
    const isValidtoBeDispensedPAFForHomecareOutPatient =
      data?.patient?.patient_type !== "Woman of Childbearing Potential" ||
      (data?.patient?.patient_type === "Woman of Childbearing Potential" &&
        data.status !== "Overdue");

    if (isValidtoBeDispensedPAFForHomecareOutPatient) {
      setContinuDispenseOverduePAF({
        ...continuDispenseOverduePAF,
        isValidNonComplaintHomecareAndOutPatient: true,
      });
    }
  };

  const handleIsPharmaApproved = (data) => {
    const is_form_approved_pharmacy = !(
      data?.status === "Submitted" ||
      data?.status === "Overdue" ||
      data?.status === "Expiring"
    );

    if (is_form_approved_pharmacy) {
      setCapsule_changes_tracker(data.history);
    }
  };

  const onOpenPAFValidations = (data) => {
    if (isPrescriber || isConnectedNurse) return;

    validateOutpatientDispense(data);
    handleIsPharmaApproved(data);

    Object.assign(data, {
      confirmation: data.confirmation_risk_prescriber,
    });

    let infoUser = {
      ...data,
    };
    Object.assign(infoUser.patient, {
      treatingHospital: infoUser.patient.hospital_name,
    });

    setUserInfo(infoUser.patient);
    delete infoUser.patient;

    setOpenedItem({
      ...data,
      // Add default value for checkbox for Approve/Reject PAF to know the initial value
      confirmation: data.confirmation_risk_patient,
    });

    setUnchagedItems({ ...data });

    validateAdditionalOptions(data);
  };

  const getItem = (id) => {
    return detailsPAF.find((element) => element.id === id);
  };

  const getDataAboutCurrentForm = async (id) => {
    const item = detailsPAF.find((element) => element.id === id);

    if (item) {
      setActivateTab(id);
      validateAdditionalOptions(item);
      setOpenedItem(item);

      onOpenPAFValidations(getItem(id));
      return item;
    } else {
      const res = await Axios.get(APIVariables.PRESCRIPTIONS + id).catch(
        (error) => {
          handleCatch(error);
        }
      );

      if (res.status === 200) {
        if (!isConnectedNurse) {
          getHCP(res.data.product_id);
        }
        setActivateTab(id);
        validateAdditionalOptions(res?.data);
        setOpenedItem(res?.data);

        setDetailsPAF([...detailsPAF, res.data]);
        setCapsule_changes_tracker(res.data.history);

        onOpenPAFValidations(res?.data);
      }
    }
  };

  const detailsSelectedPAF = (pafID) => {
    return detailsPAF.find((element) => element.id === pafID);
  };

  const getNumberOfCycles = async () => {
    if (!isPrescriber && !isConnectedNurse && !!pafID) {
      await Axios.get(APIVariables.PRESCRIPTIONS_ACCEPT + pafID)
        .then(async (res) => {
          if (res?.status === 200) {
            const dropDownValues = res?.data?.n_cycles;
            const rename = await dropDownValues;

            setNumberOfCylesValue(rename);
          }
        })
        .catch(function (error) {
          handleCatch(error);

          const errors = error?.response?.data;
          if (typeof errors === "string") {
            setErrorMessage([errorMessages.INTERNAL_SERVER_ERROR]);
          } else {
            const errorsArray = [];
            if (!!errors) {
              Object.keys(errors).forEach((key) => {
                if (isObject(errors[key])) {
                  Object.keys(errors[key]).forEach((secondKey) => {
                    errorsArray.push(key + " - " + errors[key][secondKey][0]);
                  });
                } else {
                  errorsArray.push(key + " - " + errors[key]);
                }
              });
              setErrorMessage(errorsArray);
            } else {
              setErrorMessage([error.message]);
            }
          }
        });
    }
  };

  const validateAdditionalOptions = (data) => {
    const validation = {
      capsuleStrength: !!data.strengths.length !== 0,
      capsulePerCycle: !!data.strengths.length !== 0,
      numberOfCycles: !(data.n_cycles === undefined || data.n_cycles === null),
    };

    const aditionalOptionsCopy = { ...aditionalOptions, ...validation };

    setAditionalOptions(aditionalOptionsCopy);
  };

  const getPAF = async () => {
    setIsFormApproved(null);
    if (
      isPrescriber ||
      isPharmacy ||
      isConnectedNurse ||
      isOutpatient ||
      isHomecare
    ) {
      const res = await Axios.get(
        APIVariables.PATIENTS_DETAILS + patientID
      ).catch(function (error) {
        handleCatch(error);
        setIsLoading(false);
      });

      if (res?.status === 200) {
        let infoUser = { ...res.data };
        delete infoUser.prescriptions;
        setUserInfo(infoUser);
        setItems(res.data.prescriptions);

        setIsLoading(false);
      }
    }
  };

  function closeForm() {
    if (isPharmacy) {
      if (
        getOpenedItem().status === "Approved" ||
        getOpenedItem().status === "Rejected" ||
        getOpenedItem().status === "Dispensed"
      ) {
        navigate(App.VIEW_PATIENTS);
      } else {
        setIsOpenQuit(true);
      }
    } else {
      navigate(App.VIEW_PATIENTS);
    }
  }

  function closeModalQuit() {
    setIsOpenQuit(false);
  }

  function quitModal() {
    navigate(App.VIEW_PATIENTS);
  }

  function closeModalChangeValues() {
    setIChangeValuesModal((prevState) => ({
      ...prevState,
      isOpen: false,
      title: "",
      description: "",
      typeOfError: "",
    }));
  }

  function closemodalNonCompliantPAF() {
    setModalNonCompliantPAF((prevState) => ({
      ...prevState,
      isOpen: false,
      description: [],
      screen: 1,
    }));
  }

  function closemodalNonCompliantPAFWarning() {
    setModalNonCompliantPAFWarning((prevState) => ({
      ...prevState,
      isOpen: false,
      title: "",
      description: [],
    }));
  }

  function continueButton(typeOfError) {
    if (typeOfError === "capsule_strength") {
      setConfirmChangesCapsStrength(true);
    }

    if (typeOfError === "capsules_per_cycle") {
      setConfirmChangesCapsPerCycle(true);
    }

    if (typeOfError === "confirmWasChangedPregancyTest") {
      setConfirmWasChangedPregancyTest(true);
    }

    if (typeOfError === "confirmWasApproved") {
      setConfirmWasApproved(true);
    }

    if (typeOfError === "confirmWasChangedNumberOfCycles") {
      setConfirmWasChangedNumberOfCyles(true);
    }

    if (typeOfError === "confirmWasChangedWeeksPerCycle") {
      setConfirmWasChangedWeeksPerCycle(true);
    }

    if (typeOfError === "confirmWasPregancyTestIsNotValid") {
      setConfirmWasPregancyTestIsNotValid(true);
    }

    if (typeOfError === "confirmWasDispensed7DaysAfter") {
      setConfirmWasDispensed7DaysAfter(true);
    }

    closeModalChangeValues();
  }

  const handleChangeValidPassword = (event) => {
    setValidPassword(event.target.value);
  };

  const continueButtonNonComplainPAFWarning = (title) => {
    if (title === errorMessages.OUTDATED_PREGANACY_TEST_TITLE) {
      setConfirmWasPregancyTestIsNotValid(true);
    }

    if (title === errorMessages.PREGNANACY_TEST_DATE_INCOMPLETE_TITLE) {
      setConfirmPregnancyTestDateIncompleteModal(false);
    }

    if (title === errorMessages.INDICATION_INCOMPLETE_TITLE) {
      setConfirmIndicationIncompleteModal(false);
    }

    if (title === errorMessages.MAXIM_NUMBER_OF_CYLES_EXCEEDED_TITLE) {
      setConfirmNumberOfCylesIsNotValidWarningModal(false);
    }

    if (title === " Capsules outside permitted Quantity") {
      setConfirmNumberOfWeeksIsNotValidWarningModal(false);
    }

    if (title === "Capsules outside permitted Quantity") {
      setConfirmCapsulesPerWeeksIsNotValidWarningModal(false);
    }

    if (title === "Maximum number of weeks exceeded") {
      setConfirmNumberOfWeeksIsNotValidAgainWarningModal(false);
    }

    if (title === errorMessages.CONFIRMATION_NOT_PROVIDED_TITLE) {
      setConfirmCheckboxIsNotValidWarningModal(false);
    }

    closemodalNonCompliantPAFWarning();
  };

  const continueButtonNonComplainPAF = async (screen) => {
    setErrorMessage("");
    setErrorMessageFromServer("");

    if (screen === 1) {
      setModalNonCompliantPAF((prevState) => ({
        ...prevState,
        description: [],
        screen: 2,
      }));
    }

    if (screen === 2) {
      const res = await Axios.post(APIVariables.USERS_PASSWORD_VALIDATE, {
        password: validPassword,
      }).catch(function (error) {
        handleCatch(error);

        const errors = error?.response?.data;
        if (typeof errors === "string") {
          setErrorMessageFromServer([errorMessages.INTERNAL_SERVER_ERROR]);
        } else {
          const errorsArray = [];
          if (!!errors) {
            Object.keys(errors).forEach((key) => {
              if (isObject(errors[key])) {
                Object.keys(errors[key]).forEach((secondKey) => {
                  // errorsArray.push(key + " - " + errors[key][secondKey][0]);
                  errorsArray.push(errors[key][secondKey][0]);
                });
              } else {
                // errorsArray.push(key + " - " + errors[key]);
                errorsArray.push(errors[key]);
              }
            });
            setErrorMessageFromServer(errorsArray);
          } else {
            setErrorMessageFromServer([error.message]);
          }
        }
      });

      if (res?.status === 200) {
        if (isOutpatient || isHomecare) {
          closemodalNonCompliantPAF();
          setContinuDispenseOverduePAF({
            ...continuDispenseOverduePAF,
            isValidNonComplaintHomecareAndOutPatient: true,
          });
        } else {
          setModalNonCompliantPAF((prevState) => ({
            ...prevState,
            description: [],
            screen: 3,
          }));
        }
      }
    }
    if (screen === 3) {
      if (isApproveAndDispense) {
        setShowMahSelection(true);
      } else {
        setShowNominateDispenser(true);
      }
      closemodalNonCompliantPAF();
    }
  };

  const completeNonComplaintPAF = (payload) => {
    setIsFormApproved(true);
    closemodalNonCompliantPAF();
    sendPafConclusion(true, payload);
  };

  const maximNumberOfCycledExceeded = (patientType) => {
    let number_cyles = detailsPAF[0]?.new_capsule_cycles?.id
      ? detailsPAF[0]?.new_capsule_cycles?.id
      : detailsPAF[0]?.n_cycles;

    if (typeof number_cyles === "string") {
      number_cyles = number_cyles?.split(" ")[0];
    }

    return (
      ((patientType === "Male" ||
        patientType === "Woman of Non-Childbearing Potential") &&
        +number_cyles > 3) ||
      ((patientType === "Woman of Childbearing Potential" ||
        patientType === typeOfPAF.WOMANOFCPRENEW2) &&
        +number_cyles > 1)
    );
  };

  const displayWeeks = detailsPAF[0]?.schedule_display_type === "weeks";

  const checkIfNumberOfCyclesExceeded = (form) => {
    const getNumberOfCyles = detailsPAF[0]?.new_capsule_cycles?.id
      ? detailsPAF[0]?.new_capsule_cycles?.id
      : +form?.n_cycles?.split(" ")[0];

    const typeOfFormVar = typeOfForm();
    if (
      typeOfFormVar === typeOfPAF.MALE ||
      typeOfFormVar === typeOfPAF.WOMANOFNCP
    ) {
      if (
        getNumberOfCyles >
        (detailsPAF[0]?.schedule_display_type !== "weeks" && 3)
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        getNumberOfCyles >
        (detailsPAF[0]?.schedule_display_type !== "weeks" ? 1 : 4)
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const doDispensing = async (dispensingForm) => {
    await Axios.post(
      APIVariables.PRESCRIPTIONS + pafID + APIVariables.DISPENSE,
      dispensingForm
    )
      .then((res) => {
        const { status, data } = res;
        if (status === 200) {
          navigate(App.VIEW_PATIENTS);
          setForm({ ...form, ...data });
        }
      })
      .catch(function (error) {
        handleCatch(error);
      });

    updateNotifications();
  };

  async function approveForm() {
    if (
      !isValidCapsules ||
      !isValidCycles ||
      !isValidStrengths ||
      !isValidWeeksPerCycle
    ) {
      setAditionalOptionsState({ ...aditionalOptionsState, showErrors: true });
      window.scrollTo(0, document.body.scrollHeight);
      return;
    }

    const patientType = getOpenedItem().patient.patient_type;

    const isPregancyTestOutdated = getOpenedItem().pregnancy_test
      ? isDispensingMoreThan3DaysBetween2Dates(
          getOpenedItem()?.pfa_issued,
          getOpenedItem()?.pregnancy_test
        )
      : false;
    const isPreganacyTestIncomplete =
      (getOpenedItem().pregnancy_test !== true &&
        getOpenedItem().pregnancy_test === undefined) ||
      getOpenedItem().pregnancy_test === null;

    const isNumberOfCylesExceeded = checkIfNumberOfCyclesExceeded(
      getOpenedItem()
    );
    const checkIfNumberOfWeeksExceeded = (form) => {
      const typeOfFormVar = !!form?.type ? form?.type : form?.patient?.type;

      const isWOMANOFCP = typeOfFormVar === typeOfPAF.WOMANOFCP;
      const isMALE = typeOfFormVar === typeOfPAF.MALE;
      const isWOMANOFNCP = typeOfFormVar === typeOfPAF.WOMANOFNCP;

      const maxWeeksWCBP = form?.max_weeks_wcbp || form?.max_weeks_wcbp;
      const maxWeeksMOrWNCBP =
        form?.max_weeks_m_or_wncbp || form?.max_weeks_m_or_wncbp;

      const nrCycles = !!form?.new_capsule_cycles?.id
        ? form?.new_capsule_cycles?.id
        : !!form?.n_weeks_other
        ? form?.n_weeks_other
        : !!form?.other
        ? parseFloat(form?.other)
        : parseFloat(form?.n_weeks);

      return isWOMANOFCP
        ? maxWeeksWCBP && maxWeeksWCBP > 0
          ? nrCycles > maxWeeksWCBP
          : false
        : isMALE || isWOMANOFNCP
        ? maxWeeksMOrWNCBP && maxWeeksMOrWNCBP > 0
          ? nrCycles > maxWeeksMOrWNCBP
          : false
        : false;
    };

    const isChangedWeeksPerCycle = () => {
      const checkNewWeeksPerCycle =
        (detailsPAF[0]?.new_weeks_per_cycle?.id === 0
          ? detailsPAF[0]?.new_weeks_per_cycle_other
          : detailsPAF[0]?.new_weeks_per_cycle?.id) ||
        (detailsPAF[0]?.new_weeks_per_cycle?.id === 0
          ? detailsPAF[0]?.new_weeks_per_cycle_other
          : detailsPAF[0]?.new_weeks_per_cycle?.id);
      if (checkNewWeeksPerCycle) {
        return true;
      } else {
        return false;
      }
    };

    const checkIfTotalSupplyWeeksExceeded = (form) => {
      const typeOfFormVar = !!form?.type ? form?.type : form?.patient?.type;

      const isWOMANOFCP = typeOfFormVar === typeOfPAF.WOMANOFCP;
      const isMALE = typeOfFormVar === typeOfPAF.MALE;
      const isWOMANOFNCP = typeOfFormVar === typeOfPAF.WOMANOFNCP;

      const maxWeeksWCBP = form?.max_weeks_wcbp || form?.max_weeks_wcbp;
      const maxWeeksMOrWNCBP =
        form?.max_weeks_m_or_wncbp || form?.max_weeks_m_or_wncbp;

      return isWOMANOFCP
        ? maxWeeksWCBP && maxWeeksWCBP > 0
          ? form?.total_weeks_supply > maxWeeksWCBP
          : false
        : isMALE || isWOMANOFNCP
        ? maxWeeksMOrWNCBP && maxWeeksMOrWNCBP > 0
          ? form?.total_weeks_supply > maxWeeksMOrWNCBP
          : false
        : false;
    };

    const checkIfTotalCapsulesExceeded = (form) => {
      const typeOfFormVar = !!form?.type ? form?.type : form?.patient?.type;

      if (form.schedule_display_type === "weeks") {
        const totalCapsules = !!form?.new_totalCapsules
          ? form?.new_totalCapsules
          : form?.total_capsules;

        return typeOfFormVar === typeOfPAF.WOMANOFCP
          ? form?.max_capsules_wcbp > 0 &&
              totalCapsules > form?.max_capsules_wcbp
          : form?.max_capsules_m_or_wncbp > 0
          ? totalCapsules > form?.max_capsules_m_or_wncbp
          : false;
      }
    };

    const isIndicationIncomplete =
      getOpenedItem()?.indication === undefined ||
      getOpenedItem()?.indication === null;

    if (confirmChangesCapsStrength !== true) {
      let areCapsStrengthChanged = detailsPAF[0]?.strengths.every(
        (element) => element.new_strength_id === null
      );

      if (!areCapsStrengthChanged) {
        setConfirmChangesCapsStrength(false);
        setIChangeValuesModal((prevState) => ({
          ...prevState,
          isOpen: true,
          title: "Amend the capsule strength provided for this patient",
          description: `I acknowledge that the capsule strength inputted originally was inaccurate. I wish to amend the number of ${detailsPAF[0]?.schedule_display_type} provided based on consultation with the prescriber/patient.`,
          typeOfError: "capsule_strength",
        }));
        return;
      }
    }

    if (confirmChangesCapsPerCycle !== true) {
      let areCapsCyclesChanged = detailsPAF[0]?.strengths.every(
        (element) => element.new_capsules === null
      );

      if (!areCapsCyclesChanged) {
        setConfirmChangesCapsPerCycle(false);
        setIChangeValuesModal((prevState) => ({
          ...prevState,
          isOpen: true,
          title: `Amend the capsules per ${
            detailsPAF[0]?.schedule_display_type === "weeks_per_cycle"
              ? "cycles"
              : detailsPAF[0]?.schedule_display_type
          } provided for this patient`,
          description: `I acknowledge that the capsules per ${
            detailsPAF[0]?.schedule_display_type === "weeks_per_cycle"
              ? "cycles"
              : detailsPAF[0]?.schedule_display_type
          } inputted originally was inaccurate. I wish to amend the number of ${
            detailsPAF[0]?.schedule_display_type === "weeks_per_cycle"
              ? "cycles"
              : detailsPAF[0]?.schedule_display_type
          } provided based on consultation with the prescriber/patient.`,
          typeOfError: "capsules_per_cycle",
        }));
        return;
      }
    }

    const isChangedNrCycles = () => {
      const checkNewNCycles =
        detailsPAF[0]?.new_capsule_cycles?.label === "Other (Please State)"
          ? detailsPAF[0]?.n_weeks_other
          : detailsPAF[0]?.new_capsule_cycles?.id;

      if (checkNewNCycles) {
        return true;
      } else {
        return false;
      }
    };

    if (isChangedNrCycles() && confirmWasChangedNumberOfCycles !== true) {
      setConfirmWasChangedNumberOfCyles(false);
      setIChangeValuesModal((prevState) => ({
        ...prevState,
        isOpen: true,
        title: `Amend the number of ${
          detailsPAF[0]?.schedule_display_type === "weeks_per_cycle"
            ? "cycles"
            : detailsPAF[0]?.schedule_display_type
        } provided for this patient`,
        description: `I acknowledge that the number of ${
          detailsPAF[0]?.schedule_display_type === "weeks_per_cycle"
            ? "cycles"
            : detailsPAF[0]?.schedule_display_type
        } inputted originally was inaccurate. I wish to amend the number of ${
          detailsPAF[0]?.schedule_display_type === "weeks_per_cycle"
            ? "cycles"
            : detailsPAF[0]?.schedule_display_type
        } provided based on consultation with the prescriber/patient.`,
        typeOfError: "confirmWasChangedNumberOfCycles",
      }));
      return;
    }

    if (
      detailsPAF[0]?.schedule_display_type === "weeks_per_cycle" &&
      isChangedWeeksPerCycle() &&
      confirmWasChangedWeeksPerCycle !== true
    ) {
      setConfirmWasChangedWeeksPerCycle(false);
      setIChangeValuesModal((prevState) => ({
        ...prevState,
        isOpen: true,
        title: `Amend the weeks per cycles provided for this patient`,
        description: `I acknowledge that the weeks per cycles inputted originally was inaccurate. I wish to amend the weeks per cycles provided based on consultation with the prescriber/patient.`,
        typeOfError: "confirmWasChangedWeeksPerCycle",
      }));
      return;
    }

    if (patientType === "Woman of Childbearing Potential") {
      if (isPreganacyTestIncomplete) {
        if (confirmPregnancyTestDateIncompleteModal !== false) {
          setConfirmPregnancyTestDateIncompleteModal(true);

          setModalNonCompliantPAFWarning((prevState) => ({
            ...prevState,
            isOpen: true,
            title: errorMessages.PREGNANACY_TEST_DATE_INCOMPLETE_TITLE,
            description: (
              <BrochureWithText
                type="NEED_VALID_NEGATIVE_TEST_INCOMPLETE"
                hcp={hcp}
              />
            ),
          }));
          return;
        } else {
          setConfirmPregnancyTestDateIncompleteModal(false);
        }
      } else {
        setConfirmPregnancyTestDateIncompleteModal(null);
      }
    }

    if (
      getOpenedItem()?.confirmIndicationIncompleteModal !== true &&
      isIndicationIncomplete
    ) {
      if (confirmIndicationIncompleteModal !== false) {
        setConfirmIndicationIncompleteModal(true);

        setModalNonCompliantPAFWarning((prevState) => ({
          ...prevState,
          isOpen: true,
          title: errorMessages.INDICATION_INCOMPLETE_TITLE,
          description: (
            <BrochureWithText
              type={"CONTINUE_INDICATION_INCOMPLETE"}
              hcp={hcp}
            />
          ),
        }));
        return;
      } else {
        setConfirmIndicationIncompleteModal(false);
      }
    } else {
      setConfirmIndicationIncompleteModal(false);
    }

    if (
      isNumberOfCylesExceeded &&
      detailsPAF[0]?.schedule_display_type === "cycles"
    ) {
      if (confirmNumberOfCylesIsNotValidWarningModal !== false) {
        setConfirmNumberOfCylesIsNotValidWarningModal(true);

        setModalNonCompliantPAFWarning((prevState) => ({
          ...prevState,
          isOpen: true,
          title: errorMessages.MAXIM_NUMBER_OF_CYLES_EXCEEDED_TITLE,
          description:
            patientType === "Male" ? (
              errorMessages.MAXIM_NUMBER_OF_CYLES_EXCEEDED_DESCRIPTION_MALE
            ) : patientType === "Woman of Childbearing Potential" ? (
              <BrochureWithText type={"CYCLES_EXCEEDED_WCBP"} hcp={hcp} />
            ) : (
              errorMessages.MAXIM_NUMBER_OF_CYLES_EXCEEDED_DESCRIPTION_WOMANOFNCP
            ), // to be changed
        }));
        return;
      } else {
        setConfirmNumberOfCylesIsNotValidWarningModal(false);
      }
    } else {
      setConfirmNumberOfCylesIsNotValidWarningModal(false);
    }

    if (displayWeeks && checkIfNumberOfWeeksExceeded(detailsPAF[0])) {
      if (confirmNumberOfWeeksIsNotValidWarningModal !== false) {
        setConfirmNumberOfWeeksIsNotValidWarningModal(true);

        setModalNonCompliantPAFWarning((prevState) => ({
          ...prevState,
          isOpen: true,
          title: " Capsules outside permitted Quantity",
          description: (
            <BrochureWithText
              type={"WEEKS_EXCEEDED_WCBP"}
              typeOfPAF={typeOfPAF}
              registerPatientType={detailsPAF[0]?.patient?.type}
              maxCapsulesOrWeeks={detailsPAF[0]}
              hcp={hcp}
            />
          ),
        }));
        return;
      } else {
        setConfirmNumberOfWeeksIsNotValidWarningModal(false);
      }
    } else {
      setConfirmNumberOfWeeksIsNotValidWarningModal(false);
    }

    if (displayWeeks && checkIfNumberOfWeeksExceeded(detailsPAF[0])) {
      if (confirmNumberOfWeeksIsNotValidAgainWarningModal !== false) {
        setConfirmNumberOfWeeksIsNotValidAgainWarningModal(true);

        setModalNonCompliantPAFWarning((prevState) => ({
          ...prevState,
          isOpen: true,
          title: "Maximum number of weeks exceeded",
          description: (
            <BrochureWithText
              type={"WEEKS_EXCEEDED"}
              typeOfPAF={typeOfPAF}
              registerPatientType={typeOfForm}
              maxCapsulesOrWeeks={detailsPAF[0]}
              hcp={hcp}
            />
          ),
        }));
        return;
      } else {
        setConfirmNumberOfWeeksIsNotValidAgainWarningModal(false);
      }
    } else {
      setConfirmNumberOfWeeksIsNotValidAgainWarningModal(false);
    }

    if (
      detailsPAF[0]?.schedule_display_type === "weeks_per_cycle" &&
      checkIfTotalSupplyWeeksExceeded(detailsPAF[0])
    ) {
      if (confirmNumberOfWeeksIsNotValidAgainWarningModal !== false) {
        setConfirmNumberOfWeeksIsNotValidAgainWarningModal(true);

        setModalNonCompliantPAFWarning((prevState) => ({
          ...prevState,
          isOpen: true,
          title: "Maximum number of weeks exceeded",
          description: (
            <BrochureWithText
              type={"WEEKS_EXCEEDED"}
              typeOfPAF={typeOfPAF}
              registerPatientType={typeOfForm}
              maxCapsulesOrWeeks={detailsPAF[0]}
              hcp={hcp}
            />
          ),
        }));
        return;
      } else {
        setConfirmNumberOfWeeksIsNotValidAgainWarningModal(false);
      }
    } else {
      setConfirmNumberOfWeeksIsNotValidAgainWarningModal(false);
    }

    if (displayWeeks && checkIfTotalCapsulesExceeded(detailsPAF[0])) {
      if (confirmCapsulesPerWeeksIsNotValidWarningModal !== false) {
        setConfirmCapsulesPerWeeksIsNotValidWarningModal(true);

        setModalNonCompliantPAFWarning((prevState) => ({
          ...prevState,
          isOpen: true,
          title: "Capsules outside permitted Quantity",
          description: (
            <BrochureWithText
              type={"WEEKS_EXCEEDED_WCBP"}
              typeOfPAF={typeOfPAF}
              registerPatientType={detailsPAF[0]?.patient?.type}
              maxCapsulesOrWeeks={detailsPAF[0]}
              hcp={hcp}
            />
          ),
        }));
        return;
      } else {
        setConfirmCapsulesPerWeeksIsNotValidWarningModal(false);
      }
    } else {
      setConfirmCapsulesPerWeeksIsNotValidWarningModal(false);
    }

    if (patientType !== "Woman of Non-Childbearing Potential") {
      if (allConfirmed !== true) {
        if (confirmCheckboxIsNotValidWarningModal !== false) {
          setConfirmCheckboxIsNotValidWarningModal(true);

          setModalNonCompliantPAFWarning((prevState) => ({
            ...prevState,
            isOpen: true,
            title: errorMessages.CONFIRMATION_NOT_PROVIDED_TITLE,
            description: (
              <BrochureWithText
                type={"CONFIRMATION_NOT_PROVIDED_DESCRIPTION"}
                hcp={hcp}
                confirmationRiskLength={
                  getOpenedItem()?.prescription_confirmation_risks?.length
                }
              />
            ),
          }));
          return;
        } else {
          setConfirmCheckboxIsNotValidWarningModal(false);
        }
      } else {
        setConfirmCheckboxIsNotValidWarningModal(false);
      }
    }

    setIsOtherNumberOfCycledValid(false);
    // IF OTHER OPTION IS SELECTED FROM NUMBER OF CYCLES TO OTHER AND OTHER IS VALID
    if (
      getOpenedItem().n_cycles !== unchagedItems.n_cycles ||
      getOpenedItem().n_cycles_other !== unchagedItems.n_cycles_other
    ) {
      if (getOpenedItem().n_cycles === "Other (please state)") {
        if (+getOpenedItem().n_cycles_other !== undefined) {
          setIsOtherNumberOfCycledValid(false);
        } else {
          setIsOtherNumberOfCycledValid(true);
        }
      } else {
        setIsOtherNumberOfCycledValid(false);
      }
    }

    // CHECK IF LAST NEGATIVE PREGANCY TEST WAS CHANGED ONLY FOR Woman of Childbearing Potential
    if (patientType === "Woman of Childbearing Potential") {
      if (
        getOpenedItem().pregnancy_test !== unchagedItems.pregnancy_test &&
        confirmWasChangedPregancyTest !== true
      ) {
        setConfirmWasChangedPregancyTest(false);
        setIChangeValuesModal((prevState) => ({
          ...prevState,
          isOpen: true,
          title: "Amend date of last negative pregnancy test",
          description:
            "I acknowledge that the date of the last negative pregnancy test that was originally inputted was outside the permitted date range. I wish to amend this date based on consultation with the prescriber/patient.",
          typeOfError: "confirmWasChangedPregancyTest",
        }));
        return;
      }
    }

    // CHECK IF LAST NEGATIVE PREGANCY TEST WAS CHANGED ONLY FOR Woman of Childbearing Potential and it is not valid
    if (patientType === "Woman of Childbearing Potential") {
      if (
        (getOpenedItem().pregnancy_test !== unchagedItems.pregnancy_test ||
          getOpenedItem().pregnancy_test !== null) &&
        isPregancyTestOutdated &&
        confirmWasPregancyTestIsNotValid !== true
      ) {
        setConfirmWasPregancyTestIsNotValid(false);
        setIChangeValuesModal((prevState) => ({
          ...prevState,
          isOpen: true,
          title: errorMessages.OUTDATED_PREGANACY_TEST_TITLE,
          description: (
            <BrochureWithText type={"NEED_VALID_NEGATIVE_TEST"} hcp={hcp} />
          ),
          typeOfError: "confirmWasPregancyTestIsNotValid",
        }));
        return;
      }
    }

    // CHECK IF CHECKBOX WAS CHANGED
    if (
      getOpenedItem()?.prescription_confirmation_risks !==
        unchagedItems.prescription_confirmation_risks &&
      confirmWasApproved !== true
    ) {
      setConfirmWasApproved(false);
      setIChangeValuesModal((prevState) => ({
        ...prevState,
        isOpen: true,
        title: "Confirm that the patient has been counselled",
        description:
          "I acknowledge that confirmation of patient counselling was not originally provided. I wish to amend this based on consultation with the prescriber/patient.",
        typeOfError: "confirmWasApproved",
      }));
      return;
    }

    const prescribedOnForm = form?.pfa_issued || getOpenedItem()?.pfa_issued;

    const isDispesngingMoreThan7Days = isDispensingMoreThan7DaysBetween2Dates(
      prescribedOnForm,
      new Date()
    );

    // CHECK IF PAF IS GREATER THAN 7 DAYS (PHARMACY VIEW AND WCBP PATIENT TYPE ONLY)
    if (patientType === "Woman of Childbearing Potential") {
      if (
        confirmWasDispensed7DaysAfter !== true &&
        isDispesngingMoreThan7Days
      ) {
        setConfirmWasDispensed7DaysAfter(false);
        setIChangeValuesModal((prevState) => ({
          ...prevState,
          isOpen: true,
          title: errorMessages.DISPENSING_MORE_THAN_7_DAYS_TITLE,
          description: errorMessages.DISPENSING_MORE_THAN_7_DAYS_DESCRIPTION,
          typeOfError: "confirmWasDispensed7DaysAfter",
        }));
        return;
      }
    }

    // CHECK IF PAF IS NON-COMPLIANT
    const errors = [];

    // IS PREGNANCY TEST OUTDATED

    if (patientType === "Woman of Childbearing Potential") {
      if (
        isPregancyTestOutdated &&
        (form.pregnancy_test !== undefined || form.pregnancy_test !== null)
      ) {
        errors.push("Pregnancy test date was outside the permitted date range");
      }
      if (isPreganacyTestIncomplete) {
        errors.push("Pregnancy test incomplete");
      }
    }

    // IS INDICATION INCOMPLETE
    if (isIndicationIncomplete) {
      errors.push("Indication incomplete");
    }

    // IS NUMBER OF CYCLES / WEEKS OUTDATED
    const isMaximumOfCyclesExceeded = maximNumberOfCycledExceeded(patientType);
    if (
      detailsPAF[0]?.schedule_display_type !== "weeks_per_cycle" &&
      !displayWeeks &&
      isMaximumOfCyclesExceeded
    ) {
      errors.push("Maximum number of cycles exceeded for this patient");
    }

    if (displayWeeks && checkIfNumberOfWeeksExceeded(detailsPAF[0])) {
      errors.push("Maximum number of weeks exceeded for this patient");
    }
    if (
      detailsPAF[0].schedule_display_type === "weeks_per_cycle" &&
      checkIfTotalSupplyWeeksExceeded(detailsPAF[0])
    ) {
      errors.push("Maximum number of weeks exceeded for this patient");
    }

    if (displayWeeks && checkIfTotalCapsulesExceeded(detailsPAF[0])) {
      errors.push(" Capsules outside permitted Quantity");
    }
    // VALIDATE DISPESING MORE THAN 7 DAYS

    if (patientType === "Woman of Childbearing Potential") {
      if (isDispesngingMoreThan7Days) {
        errors.push(errorMessages.DISPENSING_MORE_THAN_7_DAYS_TITLE);
      }
    }

    // VALIDATE PRESCRIBED ON
    const typeOfFormVar = typeOfForm();
    if (allConfirmed === false && typeOfFormVar !== typeOfPAF.WOMANOFNCP) {
      errors.push("Confirm that the patient has been counselled");
    }

    if (errors.length) {
      setModalNonCompliantPAF((prevState) => ({
        ...prevState,
        isOpen: true,
        description: errors,
        screen: 1,
      }));
    } else {
      let isValidPayload = await checkApproveForm();
      if (isValidPayload) {
        setStatePAFModal(true);
        setMessage((prevState) => ({
          ...prevState,
          state: 1,
          type: "valid",
          title: "Please confirm your approval",
          // description: `
          //   <div>I have read and understood the <a href="/" target="_blank" rel="noreferrer">Lenalidomide Healthcare Professional Brochure</a>. I confirm the information provided on this PAF is accurate, complete and in accordance with the requirements of the pregnancy prevention programme for Lenalidomide. </div>
          //   ${
          //     patientType === "Male"
          //       ? '<div class="mt-4">For male patients; (1) The patient has been counselled/reminded about teratogenic risk and the requirement to use a condom if sexually active with a pregnant woman or a woman of childbearing potential not using effective contraception. (2) The supply of treatment is no more than 12 weeks.</div>'
          //       : patientType === "Woman of Childbearing Potential"
          //       ? '<div class="mt-4">For a woman of childbearing potential (WCBP); (1) The WCBP has been counselled/reminded about teratogenic risk and has been on at least one effective method of contraception for at least 4 weeks. (2) The WCBP has had a negative pregnancy test within the 3 days before the prescription date. (3) The dispensing of lenalidomide is within 7 days of the prescription date. (4) The supply of treatment is no more than 4 weeks.</div>'
          //       : '<div class="mt-4">For women of non-childbearing potential the supply of treatment is no more than 12 weeks.</div>'
          //   }
          // `,
          description: (
            <BrochureWithText
              type={"PLEASE_CONFIRM_APPROVAL"}
              patientType={patientType}
              hcp={hcp}
            />
          ),
          buttonText: "Approve",
        }));
      }
    }
  }

  const closeConfirmationModal = () => {
    setStatePAFModal(false);
    setMessage((prevState) => ({
      ...prevState,
      state: 1,
      type: "",
      title: "",
      description: "",
      buttonText: "",
    }));
  };

  function rejectForm() {
    setStatePAFModal(true);
    submitError();
  }

  function revertToApprover() {
    setStatePAFModal(true);
    setMessage((prevState) => ({
      ...prevState,
      state: 3,
      type: "revert",
      title: "State the Reason for Reverting to the Approver",
      description: "",
      buttonText: "Continue",
    }));
  }

  const submitError = () => {
    setMessage((prevState) => ({
      ...prevState,
      state: 2,
      type: "error",
      title: "State the Reason for Rejection",
      description: "",
      buttonText: "Reject",
    }));
  };

  const submitValidate = () => {
    if (isApproveAndDispense) {
      setShowMahSelection(true);
    } else {
      setShowNominateDispenser(true);
    }
    closeConfirmationModal();
    setCompliantPAF(true);
  };

  const updatedStrengths = detailsPAF[0]?.strengths.map((currentObject) => {
    // Check if new_capsules_other is equal to capsules
    if (currentObject.new_capsules_other === currentObject.capsules) {
      return {
        ...currentObject,
        new_capsules_other: null,
        is_new_other_capsules: false,
        new_capsules: null,
      };
    }
    return currentObject;
  });

  const updatedN_cycles =
    !displayWeeks && !!detailsPAF[0]?.new_capsule_cycles?.id
      ? detailsPAF[0]?.new_capsule_cycles?.id
      : parseFloat(detailsPAF[0]?.n_cycles);

  const updatedN_weeks =
    displayWeeks && detailsPAF[0]?.new_capsule_cycles?.id
      ? detailsPAF[0]?.new_capsule_cycles?.id
      : isNaN(parseFloat(detailsPAF[0]?.n_weeks))
      ? 0
      : parseFloat(detailsPAF[0]?.n_weeks);

  const updateN_weeks_other = !!detailsPAF[0]?.n_weeks_other
    ? detailsPAF[0]?.n_weeks_other
    : null;

  const weeksPerCycleValue = detailsPAF[0]?.new_weeks_per_cycle
    ? detailsPAF[0].new_weeks_per_cycle.id === 0
      ? detailsPAF[0].new_weeks_per_cycle_other
      : detailsPAF[0].new_weeks_per_cycle.id
    : detailsPAF[0]?.weeks_per_cycle;

  const prescriptionRisk = {};
  detailsPAF[0]?.prescription_confirmation_risks.forEach((item) => {
    prescriptionRisk[item.confirmation_risk_id] =
      item.confirmation_risk_patient;
  });

  const dispensePAF = async (dispensingForm) => {
    const payloadToSend = {
      prescription_confirmation_risks: prescriptionRisk,
      pregnancy_test: getOpenedItem().pregnancy_test,
      strengths: updatedStrengths,
      n_cycles: updatedN_cycles,
      n_weeks: updatedN_weeks,
      n_weeks_other: updateN_weeks_other,
      weeks_per_cycle: weeksPerCycleValue,
      dispense: dispensingForm,
    };

    await Axios.post(
      APIVariables.PRESCRIPTIONS + pafID + APIVariables.APPROVE_AND_DISPENSE,
      payloadToSend
    )
      .then((res) => {
        const { status, data } = res;
        if (status === 200) {
          navigate(App.VIEW_PATIENTS);
          setForm({ ...form, ...data });
          setIsOperationInProgress(false);
        }
      })
      .catch(function (error) {
        handleCatch(error);
      });
    updateNotifications();
  };

  //info payload is the nominate object
  const sendComplaintPAF = (payload) => {
    const isFormApprovedParam = true;
    setIsFormApproved(isFormApprovedParam);
    sendPafConclusion(isFormApprovedParam, payload);
  };

  const handleChangeSelect = (selectedElement, name) => {
    if (name === "reason_reverting") {
      setRevert((prevState) => ({
        ...prevState,
        reason_revert: selectedElement,
        reason_revert_input: "",
      }));
      setErrors({ ...errors, reason_revert: "" });
    }

    if (name === "reason_rejection") {
      setForm((prevState) => ({
        ...prevState,
        [name]: selectedElement,
        reason_rejection_input: "",
      }));
      setErrors({ ...errors, reason_rejection: "" });
    }
  };

  const handleChange = (name, value) => {
    if (name === "reason_revert_input") {
      setRevert({ ...revert, [name]: value });

      setErrors({ ...errors, reason_revert_input: "" });
      if (revert.is_revert_reason_other === true) {
        if (!value?.trim().length > 0) {
          setErrors({ ...errors, reason_revert_input: "Required" });
        }
      }
    }

    if (name === "reason_rejection_input") {
      setForm({ ...form, [name]: value });
      setErrors({ ...errors, reason_rejection_input: "" });
      if (form.is_rejection_reason_other === true) {
        if (!value?.trim().length > 0) {
          setErrors({ ...errors, reason_rejection_input: "Required" });
        }
      }
    }
  };

  const handleChangeSubmitedForm = (type, date, index) => {
    if (type === "capsule_strength") {
      const { oldStrength, e } = date;
      if (parseInt(oldStrength) === e?.value) {
        return;
      }
      setConfirmChangesCapsStrength(null);
    }

    if (type === "capsules_per_cycle") {
      const { e, capsule_cycle, other_caps } = date;
      const { value, label } = e;

      if (label?.toLowerCase().includes("other")) {
        if (
          getOpenedItem()[other_caps] &&
          getFirstNumberFromString(getOpenedItem()[other_caps]) === value
        ) {
          return;
        }
      } else {
        if (
          getOpenedItem()[capsule_cycle] &&
          getFirstNumberFromString(getOpenedItem()[capsule_cycle]) === value
        ) {
          return;
        }
      }

      setConfirmChangesCapsPerCycle(null);
    }

    if (type === "pregnancy_test") {
      setConfirmWasChangedPregancyTest(null);
      setConfirmWasPregancyTestIsNotValid(null);
      const formatData = formatDateDash(date);
      const detailsPAFIndex = detailsPAF.findIndex(
        (element) => element.id === activatedItem
      );

      let setNewItems = { ...detailsPAF[detailsPAFIndex] };

      setNewItems.pregnancy_test = formatData;

      const detailsPAFCopy = [...detailsPAF];

      detailsPAFCopy[detailsPAFIndex] = setNewItems;

      setDetailsPAF(detailsPAFCopy);
    }

    if (type === "number_cycles") {
      setConfirmWasChangedNumberOfCyles(null);
      setConfirmNumberOfCylesIsNotValidWarningModal(null);

      const detailsPAFIndex = detailsPAF.findIndex(
        (element) => element.id === activatedItem
      );

      const new_number_cles_value = date.label;
      let setNewItems = { ...detailsPAF[detailsPAFIndex] };
      setNewItems.n_cycles = new_number_cles_value;

      if (new_number_cles_value !== "Other (please state)") {
        setNewItems.n_cycles_other = 0;
      }

      const detailsPAFCopy = [...detailsPAF];

      detailsPAFCopy[detailsPAFIndex] = setNewItems;

      setDetailsPAF(detailsPAFCopy);
      handleAditionalOptionsChange(true, "select", "numberOfCycles");
    }

    if (type === "confirmation") {
      setConfirmWasApproved(null);

      const detailsPAFIndex = detailsPAF.findIndex(
        (element) => element.id === activatedItem
      );

      let setNewItems = { ...detailsPAF[detailsPAFIndex] };

      const updatedConfirmationRisks =
        setNewItems.prescription_confirmation_risks.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              confirmation_risk_patient: !item.confirmation_risk_patient,
              confirmation_checked_by_pharmacist:
                !item.confirmation_risk_patient,
            };
          }
          return item;
        });

      setNewItems = {
        ...setNewItems,
        prescription_confirmation_risks: updatedConfirmationRisks,
      };

      const detailsPAFCopy = [...detailsPAF];

      detailsPAFCopy[detailsPAFIndex] = setNewItems;

      setDetailsPAF(detailsPAFCopy);
    }
  };

  const validateBeforeSending = () => {
    let err = {};
    if (message.state === 2) {
      if (form.reason_rejection === "") {
        err = { ...errors, reason_rejection: "Required" };
      }
      if (form.reason_rejection.is_rejection_reason_other) {
        if (!form.reason_rejection_input?.trim().length > 0) {
          err = { ...errors, reason_rejection_input: "Required" };
        }
      }
    }

    if (message.state === 3) {
      if (revert.reason_revert === "") {
        err = { ...errors, reason_revert: "Required" };
      }
      if (revert.reason_revert.is_revert_reason_other) {
        if (!revert.reason_revert_input?.trim().length > 0) {
          err = { ...errors, reason_revert_input: "Required" };
        }
      }
    }

    setErrors(err);

    if (Object.keys(err).length === 0) {
      const isFormApprovedParam = false;
      setIsFormApproved(isFormApprovedParam);

      sendPafConclusion(isFormApprovedParam);
    }
  };

  const checkApproveForm = async () => {
    const payload = {
      strengths: detailsPAF[0]?.strengths,
      prescription_confirmation_risks: prescriptionRisk,
      pregnancy_test: getOpenedItem().pregnancy_test,
      n_cycles:
        !displayWeeks && detailsPAF[0]?.new_capsule_cycles?.id
          ? detailsPAF[0]?.new_capsule_cycles?.id
          : parseFloat(detailsPAF[0]?.n_cycles),

      n_weeks:
        displayWeeks && detailsPAF[0]?.new_capsule_cycles?.id
          ? detailsPAF[0]?.new_capsule_cycles?.id
          : isNaN(parseFloat(detailsPAF[0]?.n_weeks))
          ? 0
          : parseFloat(detailsPAF[0]?.n_weeks),
      n_weeks_other:
        detailsPAF[0]?.new_capsule_cycles?.id === 0 ||
        detailsPAF[0]?.n_weeks === 0 ||
        isNaN(parseFloat(detailsPAF[0]?.n_weeks))
          ? detailsPAF[0]?.n_weeks_other
          : 0,

      weeks_per_cycle: weeksPerCycleValue,
    };

    let res = await Axios.post(
      APIVariables.PRESCRIPTIONS_ACCEPT + pafID + "/validate",
      payload,
      {
        headers: {
          Authorization: storageData?.token,
        },
      }
    ).catch(function (error) {
      handleCatch(error);

      const errors = error?.response?.data;
      if (typeof errors === "string") {
        setErrorMessage([errorMessages.INTERNAL_SERVER_ERROR]);
      } else {
        const errorsArray = [];
        if (!!errors) {
          Object.keys(errors).forEach((key) => {
            if (isObject(errors[key])) {
              Object.keys(errors[key]).forEach((secondKey) => {
                errorsArray.push(key + " - " + errors[key][secondKey][0]);
              });
            } else {
              errorsArray.push(key + " - " + errors[key]);
            }
          });
          setErrorMessage(errorsArray);
        } else {
          setErrorMessage([error.message]);
        }
      }
    });
    if (res?.status !== 200) {
      return false;
    }
    return true;
  };

  const sendPafConclusion = async (isFormApprovedParam, payload) => {
    let res;
    if (isFormApprovedParam) {
      const payloadToSend = {
        strengths: updatedStrengths,
        prescription_confirmation_risks: prescriptionRisk,
        pregnancy_test: getOpenedItem().pregnancy_test,
        n_cycles: updatedN_cycles,
        n_weeks: updatedN_weeks,
        n_weeks_other: updateN_weeks_other,
        weeks_per_cycle: weeksPerCycleValue,
        nominate: payload,
      };

      res = await Axios.post(
        APIVariables.PRESCRIPTIONS + pafID + APIVariables.APPROVE_AND_NOMINATE,
        payloadToSend
      ).catch(function (error) {
        handleCatch(error);

        const errors = error?.response?.data;
        if (typeof errors === "string") {
          setErrorMessage([errorMessages.INTERNAL_SERVER_ERROR]);
        } else {
          const errorsArray = [];
          if (!!errors) {
            Object.keys(errors).forEach((key) => {
              if (isObject(errors[key])) {
                Object.keys(errors[key]).forEach((secondKey) => {
                  errorsArray.push(key + " - " + errors[key][secondKey][0]);
                });
              } else {
                errorsArray.push(key + " - " + errors[key]);
              }
            });
            setErrorMessage(errorsArray);
          } else {
            setErrorMessage([error.message]);
          }
        }
      });

      if (res?.status === 200) {
        setForm({ ...form, ...res?.data });
        setIsFormApproved(true);
        closeConfirmationModal();
      }
    } else if (message.state === 2) {
      res = await Axios.put(APIVariables.PRESCRIPTIONS_REJECT + pafID, {
        rejection_reason: form.reason_rejection.value,
        rejection_reason_other: form.reason_rejection_input ?? null,
      }).catch(function (error) {
        handleCatch(error);

        const errors = error?.response?.data;
        if (typeof errors === "string") {
          setErrorMessage([errorMessages.INTERNAL_SERVER_ERROR]);
        } else {
          const errorsArray = [];
          if (!!errors) {
            Object.keys(errors).forEach((key) => {
              if (isObject(errors[key])) {
                Object.keys(errors[key]).forEach((secondKey) => {
                  errorsArray.push(key + " - " + errors[key][secondKey][0]);
                });
              } else {
                errorsArray.push(key + " - " + errors[key]);
              }
            });
            setErrorMessage(errorsArray);
          } else {
            setErrorMessage([error.message]);
          }
        }
      });

      if (res?.status === 200) {
        setIsFormApproved(false);
        setOpenedItem({
          ...items,
          status: "Rejected",
          approved_or_rejected_by:
            storageData.first_name + " " + storageData.last_name,
        });
        closeConfirmationModal();
      }
    } else {
      res = await Axios.put(APIVariables.PRESCRIPTIONS_REVERT + pafID, {
        revert_reason: revert.reason_revert.value,
        revert_reason_other: revert.reason_revert_input ?? null,
      }).catch(function (error) {
        handleCatch(error);

        const errors = error?.response?.data;
        if (typeof errors === "string") {
          setErrorMessage([errorMessages.INTERNAL_SERVER_ERROR]);
        } else {
          const errorsArray = [];
          if (!!errors) {
            Object.keys(errors).forEach((key) => {
              if (isObject(errors[key])) {
                Object.keys(errors[key]).forEach((secondKey) => {
                  errorsArray.push(key + " - " + errors[key][secondKey][0]);
                });
              } else {
                errorsArray.push(key + " - " + errors[key]);
              }
            });
            setErrorMessage(errorsArray);
          } else {
            setErrorMessage([error.message]);
          }
        }
      });

      if (res?.status === 200) {
        setIsFormApproved(false);
        if (message.state === 2) {
          setOpenedItem({
            ...items,
            status: "Rejected",
            approved_or_rejected_by:
              storageData.first_name + " " + storageData.last_name,
          });
        } else {
          setOpenedItem({
            ...items,
            status: "Revert",
            approved_or_rejected_by:
              storageData.first_name + " " + storageData.last_name,
          });
        }

        closeConfirmationModal();
      }
    }
    updateNotifications();
  };

  const displayNrCyclesSecConditionally = (element) => {
    if (
      (element?.n_cycles === undefined || element?.n_cycles === null) &&
      (element?.n_weeks === null || element?.n_weeks === undefined) &&
      (element?.n_weeks_other === null || element?.n_weeks_other === undefined)
    ) {
      return <span className="red-label font-size-16">Incomplete</span>;
    }
    let result;
    if (element.schedule_display_type === "weeks") {
      result =
        (typeof element.n_weeks_other === "number"
          ? element.n_weeks_other
          : element.n_weeks) + " Weeks";
    } else {
      result = element.n_cycles + (element.n_cycles > 1 ? " Cycles" : " Cycle");
    }

    return result;
  };

  const getNrOfWeeks = () => {
    const newCapsules = getOpenedItem()?.new_capsule_cycles?.id;

    return Boolean(newCapsules) ? (
      newCapsules + " Weeks"
    ) : typeof getOpenedItem()?.n_weeks_other === "number" ? (
      getOpenedItem()?.n_weeks_other + " Weeks"
    ) : !isNaN(parseFloat(getOpenedItem()?.n_weeks)) ? (
      parseFloat(getOpenedItem()?.n_weeks) + " Weeks"
    ) : (
      <span className="red-label font-size-16">Incomplete</span>
    );
  };

  const setFormItemsPharmacy = (item) => {
    // Update form information when editing PAF as a Pharmacy User Approve & Dispense

    const detailsPAFIndex = detailsPAF.findIndex(
      (element) => element.id === item.id
    );

    const detailsPAFCopy = [...detailsPAF];

    detailsPAFCopy[detailsPAFIndex] = item;

    setDetailsPAF(detailsPAFCopy);
  };

  const deletePropertyValue = (propName) => {
    const copyItems = { ...items };
    delete copyItems[propName];
    setOpenedItem(copyItems);
  };

  const closeOverduePAF = () => {
    setContinuDispenseOverduePAF({
      ...continuDispenseOverduePAF,
      isMainModalDisplayed: false,
    });
  };
  const continueWithOverduePAF = () => {
    setContinuDispenseOverduePAF({
      isConfirmedMainModal: true,
      isMainModalDisplayed: false,
    });
  };

  const isDispensingPAF = () => {
    if (continuDispenseOverduePAF.isValidNonComplaintHomecareAndOutPatient) {
      setIsApproveAndDispense(true);
      setShowMahSelection(true);
      return;
    }

    const patientType = getOpenedItem().patient.patient_type;
    const errors = [];

    if (
      !continuDispenseOverduePAF.isConfirmedMainModal &&
      getOpenedItem().status === "Overdue"
    ) {
      setContinuDispenseOverduePAF({
        ...continuDispenseOverduePAF,
        isMainModalDisplayed: true,
      });
      return;
    } else if (patientType === "Woman of Childbearing Potential") {
      if (
        continuDispenseOverduePAF.isConfirmedMainModal &&
        getOpenedItem().status === "Overdue"
      ) {
        errors.push(errorMessages.DISPENSING_MORE_THAN_7_DAYS_TITLE);
      }
    }

    if (errors.length) {
      setModalNonCompliantPAF((prevState) => ({
        ...prevState,
        isOpen: true,
        description: errors,
        screen: 1,
      }));
    }
  };
  const findProduct = storageData?.products?.find(
    (element) => element.product_id === getOpenedItem().product_id
  );
  const hideEditButton =
    isPharmacy &&
    findProduct?.can_renew_registration === true &&
    findProduct?.product_active === true &&
    (detailsPAF[0]?.status === "Submitted" ||
      detailsPAF[0]?.status === "Overdue" ||
      detailsPAF[0]?.status === "Expiring");

  const allConfirmed = getOpenedItem()?.prescription_confirmation_risks?.every(
    (item) => item.confirmation_risk_patient === true
  );

  if (isLoading) return <Loader />;

  return (
    <StyledPrescriptionAuthorisationForm>
      {showMahSelection ? (
        <MahSelection
          setIsOperationInProgress={setIsOperationInProgress}
          isOperationInProgress={isOperationInProgress}
          product_id={detailsPAF[0].product_id}
          receivedForm={getOpenedItem()}
          onPrevious={() => {
            setShowMahSelection(false);
            setIsOperationInProgress(false);
          }}
          openFinishModal={() => setFinishModalOpen(true)}
          setFinishModalContent={setFinishModalContent}
          dispensePAF={isHomecare || isOutpatient ? doDispensing : dispensePAF}
          pafID={pafID}
          setModalNonCompliantPAFWarning={setModalNonCompliantPAFWarning}
          hcp={hcp}
        />
      ) : !showNominateDispenser ? (
        <div className="layout-modal">
          <div className="close-icon" onClick={closeForm}>
            <img src={CloseIcon} alt="Close Icon" />
          </div>
          <div className="min-height">
            <div className="wrapper-flex custom-style">
              <h1 className="select-category-txt">
                Prescription Authorisation Form
              </h1>
              {isPrescriber || isConnectedNurse ? (
                <div className="wrapper-flex">
                  {!isConnectedNurse &&
                    findProduct?.can_renew_registration === true &&
                    findProduct?.product_active === true && (
                      <div className="renew-button">
                        {!(
                          !isLastFormApprovedAvailableForRenew && isPrescriber
                        ) && (
                          <>
                            <button
                              className="second-button"
                              onClick={() => {
                                navigate(
                                  lastFormID
                                    ? App.PAF_RENEW_DEFAULT +
                                        `${typeOfForm()}/${patientID}/${lastFormID}`
                                    : App.PAF_RENEW_DEFAULT +
                                        `${typeOfForm()}/${patientID}`
                                );
                              }}
                            >
                              Renewal PAF
                            </button>
                            <div className="renewal-available">
                              Renewal Available
                            </div>
                          </>
                        )}
                      </div>
                    )}
                </div>
              ) : (
                <div className="wrapper-flex">
                  {/*
                items.status === "Approved" || items.status === "Rejected" -> Read Only Form - You cannot do any action
                items.status === "Submitted" && isFormApproved === null -> The form can be Approved or Rejected - Step 1
                items.status === "Submitted" && isFormApproved !== null -> The form can be Approved or Rejected - Step 2 (Call to BE)
                */}
                  {/* items.status === "Approved" */}
                  {getOpenedItem()?.status === "Rejected" &&
                  isFormApproved === null ? (
                    <div></div>
                  ) : (getOpenedItem()?.status === "Overdue" ||
                      getOpenedItem().status === "Expiring" ||
                      getOpenedItem().status === "Approved" ||
                      getOpenedItem()?.status === "Submitted") &&
                    isFormApproved === null ? (
                    <div className="approve-reject-paf flex-center">
                      {!isHomecare &&
                        findProduct?.can_renew_registration === true &&
                        findProduct?.product_active === true &&
                        !isOutpatient &&
                        getOpenedItem()?.status !== "Approved" && (
                          <React.Fragment>
                            <button
                              className="approve-button btn-paf mr-4"
                              onClick={() => {
                                approveForm();
                                setIsApproveAndDispense(false);
                                setShowMahSelection(false);
                              }}
                            >
                              <span>Approve</span> <ValidWhite />
                            </button>
                            <button
                              className="approve-dispense-button mr-4"
                              // onClick={() => setShowMahSelection(true)}
                              onClick={() => {
                                setIsApproveAndDispense(true);
                                setShowNominateDispenser(false);
                                approveForm();
                              }}
                            >
                              <span>Approve & Dispense</span>
                              <ValidWhite />
                            </button>
                            <button
                              className="red-button btn-paf"
                              onClick={rejectForm}
                            >
                              <span>Reject</span> <Error />
                            </button>
                          </React.Fragment>
                        )}
                      {findProduct?.can_renew_registration === true &&
                        findProduct?.product_active === true &&
                        (isPharmacy || isHomecare || isOutpatient) &&
                        !detailsPAF[0].approved_or_rejected_by.includes(
                          "Awaiting approval"
                        ) &&
                        (getOpenedItem().status === "Approved" ||
                          getOpenedItem().status === "Overdue" ||
                          getOpenedItem().status === "Expiring") && (
                          <button
                            className="revert-approver-button btn-paf mr-4"
                            onClick={() => {
                              revertToApprover();
                            }}
                          >
                            <span>Revert To Approver</span>
                            <RevertApprover />
                          </button>
                        )}

                      {(isHomecare || isOutpatient) &&
                        findProduct?.can_renew_registration === true &&
                        findProduct?.product_active === true &&
                        (getOpenedItem().status === "Approved" ||
                          getOpenedItem().status === "Overdue" ||
                          getOpenedItem().status === "Expiring") && (
                          <button
                            className="approve-button btn-paf mr-4"
                            onClick={() => {
                              isDispensingPAF();
                            }}
                          >
                            <span>Dispense</span>
                            <ShortGreenArrow />
                          </button>
                        )}
                    </div>
                  ) : (getOpenedItem().status === "Submitted" ||
                      getOpenedItem().status === "Overdue" ||
                      getOpenedItem().status === "Expiring" ||
                      getOpenedItem().status === "Rejected" ||
                      getOpenedItem().status === "Approved") &&
                    isFormApproved !== null ? (
                    <React.Fragment>
                      <div
                        className="left-button"
                        onClick={() => {
                          navigate(App.VIEW_PATIENTS);
                        }}
                      >
                        Finish
                      </div>
                      {isFormApproved === true ? <Valid /> : <Rejected />}
                    </React.Fragment>
                  ) : (
                    <div></div>
                  )}
                </div>
              )}
            </div>
            <div className="red-label">{errorMessage}</div>

            <InfoPAF patientDetails={userInfo} />

            {(isPrescriber ||
              isPharmacy ||
              isOutpatient ||
              isHomecare ||
              isConnectedNurse) && (
              <Accordion allowZeroExpanded>
                <Virtuoso
                  // endReached={existMoreItems && fetchMoreData}
                  style={{ height: "400px", overflowX: "hidden" }}
                  data={items}
                  itemContent={(index) => (
                    <AccordionItem
                      key={index}
                      dangerouslySetExpanded={
                        getOpenedItem()?.id === items[index].id
                      }
                    >
                      <AccordionItemHeading
                        onClick={() => {
                          if (getOpenedItem()?.id === items[index].id) {
                            setActivateTab([]);
                          } else {
                            getDataAboutCurrentForm(items[index].id);
                            if (isPharmacy || isOutpatient || isHomecare) {
                              navigate(
                                `${App.PRESCRIPTION_AUTHORISATION_FORM_DEFAULT}${patientID}/${items[index].id}`,
                                { replace: true }
                              );
                            }
                          }

                          setAditionalOptionsState({
                            showErrors: false,
                            validation: {
                              capsuleStrength: {
                                isValid: false,
                              },
                              capsulePerCycle: {
                                isValid: false,
                              },
                              numberOfCycles: {
                                isValid: false,
                              },
                              numberOfWeeks: {
                                isValid: false,
                              },
                            },
                            areErrors: true,
                          });
                        }}
                      >
                        <AccordionItemButton>
                          <div className="font-size-18 mineShaft-color d-flex">
                            <div className="font-size-18 mineShaft-color">
                              PAF {items.length - index} -{" "}
                              {items[index].molecule}{" "}
                              {getOpenedItem()?.id === items[index]?.id
                                ? `${items[index]?.total_capsule_strength}mg`
                                : `${items[index]?.total_capsule_strength}mg`}
                              {" X "}
                              {getOpenedItem()?.id === items[index]?.id
                                ? getOpenedItem().schedule_display_type ===
                                  "weeks"
                                  ? getNrOfWeeks()
                                  : displayNrCyclesSecConditionally(
                                      items[index]
                                    )
                                : displayNrCyclesSecConditionally(items[index])}
                            </div>

                            <div className="paf-status">
                              <span className="font-size-16 mineShaft-color">
                                Status:
                              </span>{" "}
                              {items[index]?.status_name}
                            </div>

                            <div className="paf-info font-size-16">
                              <span>PAF Issued: </span>{" "}
                              <span className="paf-issues-date">
                                {moment(items[index].pfa_issued).format(
                                  "DD / MM / YYYY"
                                )}
                              </span>
                            </div>
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="wrapper-pannel mt-2">
                          {activatedItem === items[index].id &&
                            (isPharmacy ? (
                              <ViewPAF
                                receivedForm={detailsSelectedPAF(
                                  items[index].id
                                )}
                                handleChangeSubmitedForm={
                                  handleChangeSubmitedForm
                                }
                                numberOfCylesValues={numberOfCylesValues}
                                isOtherNumberOfCycledValid={
                                  isOtherNumberOfCycledValid
                                }
                                isFormApproved={isFormApproved}
                                handleAditionalOptionsChange={
                                  handleAditionalOptionsChange
                                }
                                aditionalOptions={aditionalOptions}
                                setAditionalOptions={setAditionalOptions}
                                aditionalOptionsState={aditionalOptionsState}
                                setFormItems={setFormItemsPharmacy}
                                dispensingForm={dispensingForm}
                                deletePropertyValue={deletePropertyValue}
                                setDetailsPAF={setDetailsPAF}
                                detailsPAF={detailsPAF}
                                updateChildObject={updateChildObject}
                                setCapsule_changes_tracker={
                                  setCapsule_changes_tracker
                                }
                                capsule_changes_tracker={
                                  capsule_changes_tracker
                                }
                                hideEditButton={hideEditButton}
                              />
                            ) : (
                              <ViewPAF
                                receivedForm={detailsSelectedPAF(
                                  items[index].id
                                )}
                                setCapsule_changes_tracker={
                                  setCapsule_changes_tracker
                                }
                                capsule_changes_tracker={
                                  capsule_changes_tracker
                                }
                              />
                            ))}
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  )}
                />
              </Accordion>
            )}
          </div>
        </div>
      ) : (
        <NominateDispenser
          openFinishModal={() => setFinishModalOpen(true)}
          onPrevious={(shouldClosePAF = false) => {
            if (shouldClosePAF) setActivateTab([]);
            setShowNominateDispenser(false);
          }}
          setFinishModalContent={setFinishModalContent}
          completePAF={
            compliantPaf ? sendComplaintPAF : completeNonComplaintPAF
          }
          pafID={pafID}
          dispensingForm={dispensingForm}
          setDispensingForm={setDispensingForm}
        />
      )}

      {modalNonCompliantPAF.isOpen && (
        <NonCompliantPAF
          modalNonCompliantPAFIsOpen={modalNonCompliantPAF.isOpen}
          closemodalNonCompliantPAF={closemodalNonCompliantPAF}
          modalNonCompliantPAF={modalNonCompliantPAF}
          continueButtonNonComplainPAF={continueButtonNonComplainPAF}
          handleChangeValidPassword={handleChangeValidPassword}
          errorMessageFromServer={errorMessageFromServer}
          setValidPassword={setValidPassword}
          hcp={hcp}
          displayWeeks={displayWeeks}
        />
      )}

      {modalNonCompliantPAFWarning.isOpen && (
        <ContinueNonCompliantPAF
          modalNonCompliantPAFWarningisOpen={modalNonCompliantPAFWarning.isOpen}
          closemodalNonCompliantPAFWarning={closemodalNonCompliantPAFWarning}
          modalNonCompliantPAFWarning={modalNonCompliantPAFWarning}
          continueButtonNonComplainPAFWarning={
            continueButtonNonComplainPAFWarning
          }
        />
      )}

      {changeValuesModalIsOpen.isOpen && (
        <ChangeValuesModal
          changeValuesModalIsOpen={changeValuesModalIsOpen.isOpen}
          closeModalChangeValues={closeModalChangeValues}
          changeValuesModal={changeValuesModalIsOpen}
          continueButton={continueButton}
        />
      )}

      {quitModalIsOpen && (
        <QuitModal
          quitModalIsOpen={quitModalIsOpen}
          closeModalQuit={closeModalQuit}
          quitModal={quitModal}
          description="Are you sure you want to cancel filling out this form? All progress will be lost and you will need to start again."
        />
      )}
      {statePAFModal && (
        <ConfirmationModal
          statePAFModal={statePAFModal}
          closeConfirmationModal={closeConfirmationModal}
          message={message}
          submitError={submitError}
          submitValidate={submitValidate}
          handleChangeSelect={handleChangeSelect}
          revert={revert}
          form={form}
          handleChange={handleChange}
          handleErrors={validateBeforeSending}
          errors={errors}
          detailsPAF={detailsPAF}
        />
      )}

      {continuDispenseOverduePAF.isMainModalDisplayed && (
        <OverdueWarningModal
          continuDispenseOverduePAF={continuDispenseOverduePAF}
          closeOverduePAF={closeOverduePAF}
          continueWithOverduePAF={continueWithOverduePAF}
        />
      )}

      {finishModalOpen && (
        <FinishModal
          finishModalOpen={finishModalOpen}
          closeFinishModal={closeFinishModal}
          onFinish={finishModalContent.onFinish}
          description={finishModalContent.content}
          title={finishModalContent.title}
        />
      )}
    </StyledPrescriptionAuthorisationForm>
  );
};
export default PrescriptionAuthorisationForm;
